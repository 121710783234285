import socketio from 'socket.io-client';
import uuidv4 from 'uuid/v4';
import state from '@/store/state';
import getters from '@/store/getters';
import { routes } from '../../config/index';

let sioDistribution = null;
let sioStatus = null;
let sioCalculation = null;
let companyUuid = null;
let storeDispatch = null;
const storeGetters = {
  translate: getters.translate(state),
};

export default {
  instance(companyUuidValue, storeDispatchRef, userId) {
    storeDispatch = storeDispatchRef;
    companyUuid = companyUuidValue;
    this.connectDistributionSocket();
    this.connectTicketSocket(userId);
    this.connectTicketCalculationSocket();
  },
  connectDistributionSocket() {
    const url = routes.distributionSocket;
    const socketOptions = {
      transports: ['websocket'],
      query: `company=${companyUuid}&EIO`,
    };

    sioDistribution = socketio(url, socketOptions);
    sioDistribution.connect();
    sioDistribution.on('connect', () => {
      // console.log('connected');
    });
    sioDistribution.on('addEvent', (message) => {
      storeDispatch('eventSocketUpdate', message.data);
    });
    sioDistribution.on('disconnect', () => {
      console.log('sioDistribution disconnected');
    });
  },
  connectTicketSocket(userId) {
    const url = routes.smTicketSocket;
    const socketOptions = {
      transports: ['websocket'],
      query: `companyUuid=${companyUuid}&EIO`,
    };

    sioStatus = socketio(url, socketOptions);
    sioStatus.connect();

    sioStatus.on('connect', () => {
      this.registerStatusSocket(userId);
    });
    sioStatus.on('disconnect', () => {
      console.log('sioStatus disconnected');
    });

    // Web code socket messages
    sioStatus.on('codeTemplateTicketAccepted', (response) => {
      storeDispatch('toggleWebCode', response.data);
    });
    sioStatus.on('codeTemplateTicketRejected', (response) => {
      storeDispatch('addBetslipError', response);
    });
    sioStatus.on('codeTemplateCreateTicketFailed', (response) => {
      storeDispatch('addBetslipError', response);
    });

    // sioStatus.on('ticketReOffered', (response) => {
    //   storeDispatch('addReOfferedTicket', response.data);
    // });

    // Payin socket messages
    sioStatus.on('ticketAccepted', (response) => {
      storeDispatch('ticketAccepted', response);
    });
    sioStatus.on('ticketRejected', (response) => {
      const error = ((response || {}).errors || [])[0] || {};
      const action = error.data.riskManagement.rules.length
        ? 'rebetRejectedTicket'
        : '';
      const message = _.isEmpty(error.data.riskManagement.rules) ? error.detail || state.translations.ticket_rejected || 'ticket_rejected' : '';
      storeDispatch('payinNotification', {
        data: error,
        status: 'rejected',
      });
      storeDispatch('updateNotifications', {
        requestUuid: error.data.ticketRequest.requestUuid,
        status: 'error',
        message,
        error,
        action,
        actionText: storeGetters.translate('rebet_rejected_ticket'),
        data: error.data,
      });
    });
    sioStatus.on('ticketCancellationSuccess', (response) => {
      console.log('Cancellation success', response);
    });
    sioStatus.on('ticketCancellationFailed', (response) => {
      console.log('Cancellation failed', response);
      storeDispatch('addBetslipError', response);
    });
    sioStatus.on('ticketAdminCancellationSuccess', (response) => {
      const notificationMessage = storeGetters.translate('ticket_cancelled');
      const hash = response.data.ticketHashes && (_.head(response.data.ticketHashes) || {}).hash;
      storeDispatch('loadLatestTickets');
      storeDispatch('addNotification', {
        type: 'notification',
        message: `${hash} ${notificationMessage}.`,
        action: '',
      });
    });
  },
  connectTicketCalculationSocket() {
    const url = routes.smTicketCalculationSocket;
    const socketOptions = {
      transports: ['websocket'],
      query: `companyUuid=${companyUuid}&EIO`,
    };

    sioCalculation = socketio(url, socketOptions);
    sioCalculation.connect();
    sioCalculation.on('connect', () => {
      this.registerCalculationSocket();
    });
    sioCalculation.on('calculateTicketResult', (message) => {
      storeDispatch('calculateTicketResult', message);
    });
    sioCalculation.on('disconnect', () => {
      console.log('sioCalculation disconnected');
    });
  },
  registerStatusSocket(userId) {
    const routingKey = uuidv4();
    storeDispatch('saveRoutingKey', routingKey);
    const data = {
      routingKey,
    };
    const type = userId ? 'Punter' : 'Web';
    if (userId) data.punterUuid = userId;
    sioStatus.emit('registration', {
      type,
      data,
    });
  },
  registerCalculationSocket() {
    sioCalculation.emit('registration', {
      type: 'Retail',
      data: {
        deviceUuid: 'calculationDevice',
      },
    });
  },
  getCalculation(ticket) {
    sioCalculation.emit('calculateTicket', ticket);
  },
  disconnectSockets() {
    sioDistribution.disconnect();
    sioStatus.disconnect();
    sioCalculation.disconnect();
  },
  reconnectStatusSocket(userId) {
    if (userId === state.settings.user.id && sioStatus) return;

    if (sioStatus) {
      sioStatus.disconnect();
      this.connectTicketSocket(userId);
    }
  },
};
