import env from './env';
import companyEnv from './companyEnv';
import appConfig from './config';
import queryParser from '../src/utility/queryParser';

const companyName = queryParser.getUrlParameter('companyName');

const serverEnv = process.env.SERVER;
const companyRoutes = (companyEnv[companyName] || {})[serverEnv];

const routes = companyRoutes || env[serverEnv];

export {
  routes,
  appConfig,
  serverEnv,
  companyName,
};
