
import uuidv4 from 'uuid/v4';
import types from './mutation-types';
import translations from '@/services/translations';
import dateRanges from '@/utility/dateRanges';
import gravityGateway from '@/services/prematchGravityGateway';

export default {
  addNotification({ commit }, payload) {
    commit(types.ADD_NOTIFICATION, payload);
  },
  closeReofferUI({ commit }) {
    commit(types.CLOSE_REOFFER_UI);
  },
  displayEmailVerificationError({ commit }, data) {
    commit(types.DISPLAY_EMAIL_VERIFICATION_ERROR, data);
  },
  globalNotificationAction({ commit, dispatch }, { actionName, payload }) {
    if (actionName === 'SMPrematch.TicketReoffer') {
      const localUuid = uuidv4();
      const reofferedTicket = _.assign({ localUuid }, payload);
      dispatch('addReOfferedTicket', reofferedTicket);
      commit(types.NOTIFICATION_REOFFER_ACTION, localUuid);
    }
  },
  notificationAction({ commit, dispatch }, { data, action, id }) {
    const ticketHash = (_.find((data || {}).ticketHashes, { type: 'Normal' }) || {}).hash;
    switch (action) {
      case 'reoffer': {
        commit(types.NOTIFICATION_REOFFER_ACTION, id);
        dispatch('notificationRemove', { data, action, id });
        break;
      }
      case 'rebetRejectedTicket': {
        dispatch('rebetTicket', ticketHash);
        dispatch('notificationRemove', { data, action, id });
        break;
      }
      default: console.log('unknown action');
    }
  },
  notificationRemove({ commit }, data) {
    commit(types.NOTIFICATION_REMOVE, data.id);
  },
  removeAllNotifications({ commit }) {
    commit(types.REMOVE_ALL_NOTIFICATIONS);
  },
  setDefaultTimesValues({ commit, getters }, fullOffer) {
    const {
      defaultTimeOption, timezone, offerDateRange, timeDateOptions,
    } = getters;
    const timeSpan = defaultTimeOption === 'comingSoon' ? (_.filter(timeDateOptions, ['name', 'comingSoon'])[0] || {}).timeSpan : '';
    const timeValues = dateRanges.generateTimeRange({
      timeValue: fullOffer ? 'all' : defaultTimeOption,
      timeSpan,
      timezone,
      offerDateRange: {
        loadFullOffer: fullOffer || offerDateRange.loadFullOffer,
        days: offerDateRange.days,
      },
    });
    commit(types.SET_DEFAULT_TIME_VALUES, timeValues);
  },
  loginRequired() {
    gravityGateway.sendMessage('User.LoginRequired');
  },
  loadTranslations({ commit, getters }) {
    const { language } = getters.settings;
    const { id } = getters.settings.company;

    translations.get(language, id)
      .then((response) => {
        commit(types.SET_TRANSLATIONS, response);
      }).catch(() => {});
  },
  setTranslations({ commit }, data) {
    commit(types.SET_TRANSLATIONS, data);
  },
  toggleOnlineStatus({ commit }, payload) {
    commit(types.TOGGLE_CONNECTION_STATUS, payload);
  },
  toggleReofferPopup({ commit }) {
    commit(types.TOGGLE_REOFFER_POPUP);
  },
  toggleSidebar({ commit }, data) {
    commit(types.TOGGLE_SIDEBAR, data);
  },
  updateNotifications({ commit }, payload) {
    commit(types.UPDATE_NOTIFICATIONS, payload);
  },
  updateSettings({ commit }, data) {
    commit(types.UPDATE_SETTINGS, data);
  },
  updateConfig({ commit }, data) {
    commit(types.UPDATE_CONFIG, data);
  },
  updateUser({ commit }, user) {
    commit(types.UPDATE_USER, user);
  },
  updateUserBalance({ commit }, data) {
    commit(types.UPDATE_BALANCE, data);
  },
  updateUserToken({ commit }, data) {
    commit(types.UPDATE_USER_TOKEN, data);
  },
  verifyEmail() {
    if (gravityGateway) {
      gravityGateway.sendMessage('Router.ChangeRoute', { route: '/user/profile' });
    }
  },
  setCompanyName({ commit }, companyName) {
    commit(types.SET_COMPANY_NAME, companyName);
  },
  setCpvUuid({ commit }, value) {
    commit(types.SET_CPV_UUID, value);
  },
};
