import Vue from 'vue';
import store from '@/store';

Vue.filter('uppercase', str => _.toUpper(str));

Vue.filter('lowercase', str => _.lowerCase(str));

// Translate
Vue.filter('translate', key => store.getters.translate(key));

Vue.filter('valueTranslate', (key, values) => store.getters.valueTranslate(key, values));

Vue.filter('capitalize', str => _.capitalize(str));

Vue.filter('underscore', str => _.replace(str, / /g, '_'));
