export default {
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  CLOSE_REOFFER_UI: 'CLOSE_REOFFER_UI',
  DISPLAY_EMAIL_VERIFICATION_ERROR: 'DISPLAY_EMAIL_VERIFICATION_ERROR',
  NOTIFICATION_REMOVE: 'NOTIFICATION_REMOVE',
  NOTIFICATION_REOFFER_ACTION: 'NOTIFICATION_REOFFER_ACTION',
  REMOVE_ALL_NOTIFICATIONS: 'REMOVE_ALL_NOTIFICATIONS',
  SET_COMPANY_NAME: 'SET_COMPANY_NAME',
  SET_CPV_UUID: 'SET_CPV_UUID',
  SET_DEFAULT_TIME_VALUES: 'SET_DEFAULT_TIME_VALUES',
  SET_TRANSLATIONS: 'SET_TRANSLATIONS',
  TOGGLE_CONNECTION_STATUS: 'TOGGLE_CONNECTION_STATUS',
  TOGGLE_REOFFER_POPUP: 'TOGGLE_REOFFER_POPUP',
  TOGGLE_SIDEBAR: 'TOGGLE_SIDEBAR',
  UPDATE_BALANCE: 'UPDATE_BALANCE',
  UPDATE_CONFIG: 'UPDATE_CONFIG',
  UPDATE_NOTIFICATIONS: 'UPDATE_NOTIFICATIONS',
  UPDATE_SETTINGS: 'UPDATE_SETTINGS',
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_USER_TOKEN: 'UPDATE_USER_TOKEN',
};
