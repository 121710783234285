import balkanbet from './balkanbet';
import bmk from './bmk';
import futurebet from './futurebet';
import hemingwaynew from './hemingwaynew';
import lutrijabosnaihercegovina from './lutrijabosnaihercegovina';
import simacommunication from './simacommunication';
import topbet from './topbet';
import winner from './winner';
import isiparyaj from './isiparyaj';
import wettnet from './wettnet';
import playbetr from './playbetr';
import betflip from './betflip';
import betcoinsportsxmb from './betcoinsportsxmb';
import victory from './victory';
import sportnaloterija from './sportnaloterija';
import avalon from './avalon';
import powerbet from './powerbet';

export default {
  balkanbet,
  bmk,
  futurebet,
  isiparyaj,
  hemingwaynew,
  lutrijabosnaihercegovina,
  simacommunication,
  topbet,
  winner,
  wettnet,
  playbetr,
  betflip,
  betcoinsportsxmb,
  victory,
  sportnaloterija,
  lutrijaslovenije: sportnaloterija,
  victory1: victory,
  victory2: victory,
  victorybase: victory,
  victorynew: victory,
  avalon,
  powerbet,
};
