/**
 * Initiate offer load sequence.
 * @param {Function} storeDispatch
 */
const offerLoadSequence = (storeDispatch, routeParams = {}) => {
  const {
    sport, category, tournament, topOffer, event,
  } = routeParams;
  if (sport) {
    storeDispatch('setSportId', Number(routeParams.sport));
  }
  if (category) {
    storeDispatch('setCategoryId', Number(routeParams.category));
  }
  if (tournament) {
    storeDispatch('setTournamentId', Number(routeParams.tournament));
  }
  storeDispatch('loadMeta', { preSelectedSport: !!sport || !!topOffer })
    .then((metaResponse) => {
      const { defaultEventsLoad, metaData } = metaResponse;
      const notSportsLoad = defaultEventsLoad !== 'sports' && defaultEventsLoad !== 'bonusSports';
      storeDispatch('loadTopOffer', true)
        .then((response) => {
          if (topOffer && metaData.tournaments[topOffer]) {
            storeDispatch('setTopOffer', metaData.tournaments[topOffer]);
            return;
          }
          if (response.sports.length && notSportsLoad) {
            storeDispatch('selectAllTopOffer');
          } else {
            storeDispatch('loadFullSportEvents');
          }
          if (event) {
            storeDispatch('loadEvent', { id: event });
          }
          storeDispatch('metaLoading');
        }).catch(() => {
          storeDispatch('metaLoading');
        });
    }).catch(() => {});
};

export default offerLoadSequence;
