export default {
  development: {
    api: 'https://dev-sportsbook-sm-distribution-api.nsoft.com',
    ticketApi: 'https://dev-sportsbook-sm-ticket.nsoft.com',
    distributionSocket: 'https://dev-sportsbook-sm-distribution-socket.nsoft.com',
    smGateway: 'https://dev-sb-sm-gateway.nsoft.com',
    smTicketSocket: 'https://dev-sportsbook-sm-ticket-socket.nsoft.com',
    smTicketCalculationSocket: 'https://dev-sportsbook-sm-ticket-calculator.nsoft.com',
    gravityApi: 'https://dev-gravity-service.7platform.com',
    tc: 'https://tc-api.nsoft.com/v2/b2b/translations',
    reporting: 'https://dev-sportsbook-sm-reporting-api.nsoft.com/api/admin/report',
    sevenApi: 'https://dev-seven-platform.7platform.com',
    liveSocket: 'https://sportsbook-public-api-dev.nsoft.com',
    scoutPlugin: 'https://seven-scout-staging.nsoft.ba/',
    liveTickets: 'https://services-dev.7platform.info/web',
    liveCashout: 'https://sports-mts-cashout-dev.nsoft.com',
    prematchCashout: 'https://dev-sports-sm-cashout.nsoft.com',
    cms: 'https://svncms-cdn.s3.eu-central-1.amazonaws.com/assets/cms/dev',
  },
  staging: {
    api: 'https://staging-sportsbook-sm-distribution-api.nsoft.com',
    ticketApi: 'https://staging-sportsbook-sm-ticket.nsoft.com',
    distributionSocket: 'https://staging-sportsbook-sm-distribution-socket.nsoft.com',
    smGateway: 'https://staging-sb-sm-gateway.nsoft.com',
    smTicketSocket: 'https://staging-sportsbook-sm-ticket-socket.nsoft.com',
    smTicketCalculationSocket: 'https://staging-sportsbook-sm-ticket-calculator.nsoft.com',
    gravityApi: 'https://staging-gravity-service.7platform.com',
    tc: 'https://tc-api.nsoft.com/v2/b2b/translations',
    reporting: 'https://staging-sportsbook-sm-reporting-api.nsoft.com/api/admin/report',
    sevenApi: 'https://services-staging.7platform.com',
    liveSocket: 'https://sportsbook-public-api-staging.nsoft.com',
    scoutPlugin: 'https://seven-scout-staging.nsoft.ba/',
    liveTickets: 'https://services-staging.7platform.com/web',
    liveCashout: 'https://sports-mts-cashout-staging.nsoft.com',
    prematchCashout: 'https://staging-sports-sm-cashout.nsoft.com',
    cms: 'https://svncms-cdn.s3.eu-central-1.amazonaws.com/assets/cms/staging',
  },
  production: {
    api: 'https://sportsbook-sm-distribution-api.nsoft.com',
    ticketApi: 'https://sportsbook-sm-ticket.nsoft.com',
    distributionSocket: 'https://sportsbook-sm-distribution-socket.nsoft.com',
    smGateway: 'https://sportsbook-sm-gateway.nsoft.com',
    smTicketSocket: 'https://sportsbook-sm-ticket-socket.nsoft.com',
    smTicketCalculationSocket: 'https://sportsbook-sm-ticket-calculator.nsoft.com',
    gravityApi: 'https://gravity-service.7platform.com',
    tc: 'https://tc-api.nsoft.com/v2/b2b/translations',
    reporting: 'https://sportsbook-sm-reporting-api.nsoft.com/api/admin/report',
    sevenApi: 'https://services{dcUrlFragment}.7platform.com',
    liveSocket: 'https://sportsbook-public-api.nsoft.com',
    scoutPlugin: ' https://seven-plugin-scout.7platform.com/',
    liveTickets: 'https://services{dcUrlFragment}.7platform.info/web',
    liveCashout: 'https://sports-mts-cashout.nsoft.com',
    prematchCashout: 'https://sports-sm-cashout.nsoft.com',
    cms: 'https://svncms-cdn.s3.eu-central-1.amazonaws.com/assets/cms/production',
  },
};
