export default {
  desktop: {
    EventsOfferSportHeader: () => import('@/components/desktop/offer-events/EventsOfferSportHeader'),
    EventsTopOfferHeader: () => import('@/components/desktop/offer-events/EventsTopOfferHeader'),
    EventsBonusHeader: () => import('@/components/desktop/offer-events/bonus-bets-offer/EventsBonusHeader'),
    EventsTimelistHeader: () => import('@/components/desktop/offer-events/EventsTimelistHeader'),
    MarketsHeader: () => import('@/components/desktop/offer-events/MarketsHeader'),
    EventsOutcomesHeader: () => import('@/components/desktop/offer-events/EventsOutcomesHeader'),
    EventRow: () => import('@/components/desktop/offer-events/EventRow'),
    EventRowOutcomes: () => import('@/components/desktop/offer-events/EventRowOutcomes'),
    EventviewHeader: () => import('@/components/desktop/eventview/EventviewHeader'),
    BetGroupsFavoritesButton: () => import('@/components/common/BetGroupsFavoritesButton'),
    EventviewMarketsDropdown: () => import('@/components/desktop/eventview/EventviewMarketsDropdown'),
    EventviewBody: () => import('@/components/desktop/eventview/EventviewBody'),
    EventviewOutcome: () => import('@/components/desktop/eventview/EventviewOutcome'),
    EventviewMarketHeader: () => import('@/components/desktop/eventview/EventviewMarketHeader'),
    BetslipMeta: () => import('@/components/desktop/betslip/BetslipMeta'),
    BetslipSystemCheck: () => import('@/components/desktop/betslip/BetslipSystemCheck'),
    Betslip: () => import('@/components/desktop/betslip/Betslip'),
    ThemeImporter: () => import(/* webpackChunkName: "themeWrap" */'./ThemeImporter'),
    LiveThemeImporter: () => import(/* webpackChunkName: "themeWrapLive" */'./LiveThemeImporter'),
    LiveLightThemeImporter: () => import(/* webpackChunkName: "themeWrapLiveLight" */'./LiveLightThemeImporter'),
    TicketPreview: () => import('@/components/common/TicketPreview'),
  },
  mobile: {
    EventViewHeader: () => import('@/components/mobile/event-view/EventViewHeader'),
    BetGroupsFavoritesButton: () => import('@/components/common/BetGroupsFavoritesButton'),
    MarketOutcomes: () => import('@/components/mobile/event-view/MarketOutcomes'),
    EventMarkets: () => import('@/components/mobile/event-view/EventMarkets'),
    HeaderFilters: () => import('@/components/mobile/header-filters/HeaderFilters'),
    CategoryListHeader: () => import('@/components/mobile/events-offer/CategoryListHeader'),
    EventRow: () => import('@/components/mobile/events-offer/EventRow'),
    BetItems: () => import('@/components/common/BetItems'),
    BetslipHeaderTop: () => import('@/components/mobile/betslip/BetslipHeaderTop'),
    BetslipMeta: () => import('@/components/mobile/betslip/BetslipMeta'),
    BetslipSystems: () => import('@/components/mobile/betslip/BetslipSystems'),
    TicketCheckBets: () => import('@/components/mobile/ticket-check/TicketCheckBets'),
    TicketCheckMeta: () => import('@/components/mobile/ticket-check/TicketCheckMeta'),
    TicketCheckHeader: () => import('@/components/mobile/ticket-check/TicketCheckHeader'),
    TicketReofferHeader: () => import('@/components/mobile/ticket-reoffer/TicketReofferHeader'),
    TicketReofferBets: () => import('@/components/mobile/ticket-reoffer/TicketReofferBets'),
    TicketReofferMeta: () => import('@/components/mobile/ticket-reoffer/TicketReofferMeta'),
    EmptyEvents: () => import('@/components/mobile/events-offer/EmptyEvents'),
    ThemeImporter: () => import(/* webpackChunkName: "themeWrap" */'./ThemeImporter'),
    LiveThemeImporter: () => import(/* webpackChunkName: "themeWrapLive" */'./LiveThemeImporter'),
    LiveLightThemeImporter: () => import(/* webpackChunkName: "themeWrapLiveLight" */'./LiveLightThemeImporter'),
    TicketPreview: () => import('@/components/common/TicketPreview'),
  },
};
