let dataLayer = 'dataLayer';

const attachGoogleTagManager = (gtmId, tagName = 'script', layer = 'dataLayer') => {
  dataLayer = layer;
  window[layer] = window[layer] || [];
  window[layer].push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  });
  const firstTagElement = document.getElementsByTagName(tagName)[0];
  const gtmScriptElement = document.createElement(tagName);
  const dl = layer !== 'dataLayer' ? `&l=${layer}` : '';
  gtmScriptElement.async = true;
  gtmScriptElement.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}${dl}`;
  firstTagElement.parentNode.insertBefore(gtmScriptElement, firstTagElement);
};

const gTagMixin = {
  methods: {
    sendGTagEvent(variable = '', value = '') {
      const payload = JSON.stringify({ [variable]: value });
      window[dataLayer].push(payload);
    },
  },
};

export {
  attachGoogleTagManager,
  gTagMixin,
};
