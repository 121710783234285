const EVENT_CATEGORY = 'SEVEN.SMPrematch';

const attachScript = () => {
  const ga = document.createElement('script');
  ga.type = 'text/javascript';
  ga.async = true;
  ga.src = 'https://www.google-analytics.com/analytics.js';
  document.body.appendChild(ga);
};

const ga = (...args) => {
  if (window.ga.q) {
    window.ga.q.push(args);
    return;
  }
  (window.ga.q = window.ga.q || []).push(args);
};

const gaInit = (companyAnalytics = {}) => {
  attachScript();
  const trackingId = (companyAnalytics || {})[process.env.SERVER];
  if (!trackingId) return;
  window.ga = window.ga || ga;
  window.ga.l = +new Date();

  ga('create', trackingId, 'auto');
  ga('send', 'pageview');
};

const gaMixin = {
  methods: {
    sendGAEvent(eventAction = '', eventLabel = '') {
      if (window.ga) window.ga('send', 'event', EVENT_CATEGORY, eventAction, eventLabel);
    },
  },
};

export {
  gaInit,
  gaMixin,
};
