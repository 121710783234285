export default {
  lutrijabosnaihercegovina: {
    staging: {
      api: 'https://staging-sportsbook-sm-distribution-api.nsoft.com',
      ticketApi: 'https://staging-sportsbook-sm-ticket.nsoft.com',
      distributionSocket: 'https://staging-sportsbook-sm-distribution-socket.nsoft.com',
      smGateway: 'https://staging-sb-sm-gateway.nsoft.com',
      smTicketSocket: 'https://staging-sportsbook-sm-ticket-socket.nsoft.com',
      smTicketCalculationSocket: 'https://staging-sportsbook-sm-ticket-calculator.nsoft.com',
      gravityApi: 'https://staging-gravity-service.7platform.com',
      tc: 'https://tc-api.nsoft.com/v2/b2b/translations',
      reporting: 'https://staging-sportsbook-sm-reporting-api.nsoft.com/api/admin/report',
      sevenApi: 'https://services-staging.7platform.com',
      scoutPlugin: 'https://seven-scout-staging.nsoft.ba/',
      liveSocket: 'https://sportsbook-public-api-staging.nsoft.com',
      liveTickets: 'https://services-staging.7platform.com/web',
      liveCashout: 'https://sports-mts-cashout-staging.nsoft.com',
    },
    production: {
      api: 'https://sportsbook-sm-distribution-api.nsoft.com',
      ticketApi: 'https://sportsbook-sm-ticket.lutrijabih.ba',
      distributionSocket: 'https://sportsbook-sm-distribution-socket.nsoft.com',
      smGateway: 'https://sportsbook-sm-gateway.lutrijabih.ba',
      smTicketSocket: 'https://sportsbook-sm-ticket-socket.lutrijabih.ba',
      smTicketCalculationSocket: 'https://sportsbook-sm-ticket-calculator.lutrijabih.ba',
      gravityApi: 'https://gravity-service.7platform.com',
      tc: 'https://tc-api.nsoft.com/v2/b2b/translations',
      reporting: 'https://sportsbook-sm-reporting-api.lutrijabih.ba/api/admin/report',
      sevenApi: 'https://services{dcUrlFragment}.7platform.com',
      scoutPlugin: ' https://seven-plugin-scout.7platform.com/',
      liveSocket: 'https://sportsbook-public-api.nsoft.com',
      liveTickets: 'https://services{dcUrlFragment}.7platform.com/web',
      liveCashout: 'https://sports-mts-cashout.nsoft.com',
    },
  },
};
