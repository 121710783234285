export default {
  appOnline: true,
  config: {
    lmtLoaded: false,
  },
  defaultTimeValues: {},
  emailVerificationError: false,
  emailVerificationErrorMessage: '',
  notifications: [],
  reOfferedTicketUIActive: false,
  reofferedTicketLocalUuid: '',
  companyName: process.env.COMPANY,
  settings: {
    debug: true,
    user: {},
    routingKey: '',
    timezone: '',
    company: {
      // uuid: 'f0008dfe-212b-4492-b803-8877ff1d1ac4', // Premier - dev
      uuid: 'b99752b3-443c-4c80-b559-945a95c4b805', // Prva firma - staging
    },
    language: 'en',
    auth: {},
    deliveryPlatformId: 3,
  },
  sidebarExpanded: false,
  translations: {},
  balance: 0,
  cpvUuid: '',
};
