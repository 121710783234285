import Gateway from '@nsoft/seven-gravity-gateway';
import { serverEnv, appConfig } from '../../config';
import { gaInit } from './gAnalytics';
import { attachGoogleTagManager } from './gTag';
import socket from './socket';
// import sentry from './sentry';
import offerLoadSequence from '@/utility/offerLoadSequence';
import api from '@/api';

const configRetryTimeout = 5000;
let configRetryCount = 10;
let retryTimeoutInstance = null;

let gateway;
let storeDispatch;
let routeParams;
let localCompanyConfig;

const appLoad = ({ data }) => {
  gateway.sendMessage({ action: 'Slave.Loaded', data: {} });

  clearTimeout(retryTimeoutInstance);
  const { companyName } = routeParams;
  const product = 'prematch';
  const cpvUuid = (data.product || {}).cpvUuid
    || (appConfig[companyName] || {}).cpvUuid;

  storeDispatch('updateSettings', data);

  if (routeParams.ticket) {
    storeDispatch('checkCode',
      { ticketCode: routeParams.ticket, global: true });
  }

  if ((data.user || {}).balance) {
    storeDispatch('updateUserBalance', data.user.balance);
  }

  const userId = (data.user || {}).id || '';
  socket.instance(data.company.id, storeDispatch, userId);

  Promise.all([
    api.getCompanyAppSettings(companyName, product),
    api.getWebAppConfig(data.company),
  ]).then(([{ appSettings }, gatewayConfig]) => {
    const { config: companyAppSettings } = appSettings;
    const companyConfig = _.assign(
      {},
      localCompanyConfig,
      companyAppSettings,
      gatewayConfig,
    );
    const gTagID = ((companyConfig.googleTagManagerContainerID || {})[serverEnv]) || '';

    if (cpvUuid) companyConfig.cpvUuid = cpvUuid;
    storeDispatch('updateConfig', companyConfig);
    storeDispatch('setProcessMode', { mode: serverEnv });
    storeDispatch('updateBetslipSettings', { config: companyConfig });
    storeDispatch('setDefaultTimesValues', !!routeParams.sport || !!routeParams.topOffer);
    storeDispatch('setGridMode', companyConfig.defaultGridMode);
    storeDispatch('loadLocalUserFavorites');
    offerLoadSequence(storeDispatch, routeParams);
    if (companyConfig.bannersEnabled) storeDispatch('getBanners');
    if (companyConfig.mobSidebarDisplayedOnLoad) storeDispatch('toggleMobileSportFilter', true);

    storeDispatch('initCashoutSocket');
    storeDispatch('loadCashoutTickets').then(() => storeDispatch('subscibeCashoutTickets'));

    // sentry.init(data);
    gaInit(data.company.analytics);
    attachGoogleTagManager(gTagID);
    storeDispatch('loadTranslations');
    storeDispatch('getTicketFromStorage');
  }).catch((error) => {
    if (!configRetryCount) {
      console.log('Error in fetching config. ', error);
      return;
    }
    retryTimeoutInstance = setTimeout(() => {
      appLoad({ data, product });
    }, configRetryTimeout);
    configRetryCount -= 1;
  });
};

const setListeners = () => {
  gateway.on('User.BalanceChanged', ({ data }) => {
    storeDispatch('updateUserBalance', data.balance);
  });
  gateway.on('Widget.ClickedOutside', () => {
    storeDispatch('closeDropdown');
    storeDispatch('closeMobileWidgets');
    storeDispatch('closeMobileBetslip');
  });
  gateway.on('User.AuthorizationChanged', ({ data }) => {
    const { auth } = data;
    const { user, token, tpToken } = auth;
    const userId = user && user.id ? user.id : '';
    socket.reconnectStatusSocket(userId);
    storeDispatch('updateUser', user);
    if (user.logged) {
      storeDispatch('updateUserToken', { token, tpToken }).then(() => {
        const { dc } = localCompanyConfig;
        storeDispatch('loadUserFavorites', dc);
      });
      storeDispatch('initCashoutSocket');
      storeDispatch('loadLatestTickets');
      storeDispatch('getPlayerSettings');
      storeDispatch('loadCashoutTickets').then(() => storeDispatch('subscibeCashoutTickets'));
    } else {
      storeDispatch('clearTicketsHistory');
      storeDispatch('loadLocalUserFavorites');
      storeDispatch('removeAllNotifications');
      storeDispatch('setSelectedTab', 'betslip');
    }
  });
  gateway.on('User.ProfileChanged', () => {});
  gateway.on('Notification.Clicked', ({ data = {} }) => {
    storeDispatch('globalNotificationAction', data);
  });
  gateway.on('Widget.ToggleBetslip', () => {});
  gateway.on('UI.Show', ({ data }) => storeDispatch('handleShowUI', data));
  gateway.on('UI.Hide', ({ data }) => storeDispatch('handleHideUI', data));
};

export default {
  init({ dispatch, params }) {
    storeDispatch = dispatch;
    routeParams = params;

    if (routeParams.companyName) {
      storeDispatch('setCompanyName', routeParams.companyName);
      localCompanyConfig = _.assign(appConfig.default, appConfig[routeParams.companyName]);
    }
    const gatewayConstructor = Gateway.slave;
    gateway = gatewayConstructor({
      debug: true,
      allowedOrigins: '*',
      slaveId: 'PluginSMGatewayPrematch',
      data: {
        title: 'SM prematch client',
      },
      eventPropagation: {
        click: true,
      },
      load: appLoad,
    });
    setListeners();
    this.sendMessage('Tickets.Changed', { ticket: { betCount: 0 } });
  },
  sendMessage(action, data) {
    const gatewayDataConstruct = { action, data };
    if (gateway) gateway.sendMessage(gatewayDataConstruct);
  },
};
